import dayjs, { Dayjs } from 'dayjs';

declare module 'dayjs' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Dayjs {
    businessAdd(number: number, period?: string): Dayjs;
    businessDaysIntoMonth(): number;
    businessDiff(param: Dayjs, relative?: boolean): number;
    businessSubtract(number: number, period?: string): Dayjs;
    businessWeeksBetween(endDate?: Dayjs): Dayjs[][];
    isBusinessDay(): boolean;
    isHoliday(): boolean;
    monthBusinessDays(partialEndDate?: Dayjs): Dayjs[];
    monthBusinessWeeks(fromToday?: boolean): Dayjs[][];
    monthNaturalDays(fromToday?: boolean): Dayjs[];
    monthNaturalWeeks(fromToday?: boolean): Dayjs[][];
    nextBusinessDay(): Dayjs;
    prevBusinessDay(): Dayjs;
  }

  export function updateBusinessDays(locale: string, options: any): void;
}

export default (_option, dayjsClass, dayjsFactory) => {
  dayjsFactory.updateBusinessDays = function(locale, options) {
    if (dayjsFactory.Ls[locale]) {
      dayjsFactory.Ls[locale] = Object.assign({}, dayjsFactory.Ls[locale], options);
    }
    else {
      console.error(`dayjs.updateBusinessDays(): Locale ${locale} has not been loaded. You likely need to import 'dayjs/locale/${locale}'`);
    }
  };

  dayjsClass.prototype.isHoliday = function() {
    const locale = this.buisnessDaysConfig?.[this.locale()] || {};

    if (locale.holidays && locale.holidays.indexOf(this.format(locale.holidayFormat)) >= 0) {
      return true;
    }

    if (locale.holiday) {
      return !!locale.holiday(this);
    }

    return false;
  };

  dayjsClass.prototype.isBusinessDay = function() {
    const locale = this.buisnessDaysConfig?.[this.locale()] || {};
    const defaultWorkingWeekdays = [1, 2, 3, 4, 5];
    const workingWeekdays = locale.workingWeekdays || defaultWorkingWeekdays;

    if (locale.forcedBusinessDays && locale.forcedBusinessDays.indexOf(this.format(locale.forcedBusinessDaysFormat)) >= 0) {
      return true;
    }

    if (this.isHoliday()) {
      return false;
    }

    return workingWeekdays.indexOf(this.day()) >= 0;
  };

  dayjsClass.prototype.businessDaysIntoMonth = function() {
    if (!this.isValid()) {
      return NaN;
    }

    const businessDay = this.isBusinessDay() ? this : this.prevBusinessDay();
    const monthBusinessDays = businessDay.monthBusinessDays();
    let businessDaysIntoMonth;

    monthBusinessDays.forEach((day, index) => {
      if (day.format('M/DD/YY') === businessDay.format('M/DD/YY')) {
        businessDaysIntoMonth = index + 1;
      }
    });

    return businessDaysIntoMonth;
  };

  dayjsClass.prototype.businessDiff = function(param: Dayjs, relative) {
    const d1 = this.clone();
    const d2 = param.clone();
    const positive = d1 >= d2;
    let start = d1 < d2 ? d1 : d2;
    const end = d2 > d1 ? d2 : d1;
    let daysBetween = 0;

    if (start.isSame(end, 'day')) {
      return daysBetween;
    }

    while (start.isBefore(end, 'day')) {
      if (start.isBusinessDay()) {
        daysBetween += 1;
      }

      start = start.add(1, 'd');
    }

    if (!end.isBusinessDay()) {
      daysBetween -= 1;
    }

    if (relative) {
      return (positive ? daysBetween : -daysBetween);
    }

    return daysBetween;
  };

  dayjsClass.prototype.businessAdd = function(number, period) {
    let day = this.clone();

    if (!day.isValid()) {
      return day;
    }

    if (number < 0) {
      number = Math.round(-1 * number) * -1;
    }
    else {
      number = Math.round(number);
    }

    const signal = number < 0 ? -1 : 1;
    period = typeof period !== 'undefined' ? period : 'days';

    let remaining = Math.abs(number);

    while (remaining > 0) {
      day = day.add(signal, period);

      if (day.isBusinessDay()) {
        remaining -= 1;
      }
    }

    return day;
  };

  dayjsClass.prototype.businessSubtract = function(number, period) {
    return this.businessAdd(-number, period);
  };

  dayjsClass.prototype.nextBusinessDay = function() {
    const locale = this.buisnessDaysConfig?.[this.locale()] || {};
    let loop = 1;
    const defaultNextBusinessDayLimit = 7;
    const limit = locale.nextBusinessDayLimit || defaultNextBusinessDayLimit;

    while (loop < limit) {
      if (this.add(1, 'd').isBusinessDay()) {
        break;
      }

      loop += 1;
    }

    return this;
  };

  dayjsClass.prototype.prevBusinessDay = function() {
    const locale = this.buisnessDaysConfig?.[this.locale()] || {};
    let loop = 1;
    const defaultPrevBusinessDayLimit = 7;
    const limit = locale.prevBusinessDayLimit || defaultPrevBusinessDayLimit;

    while (loop < limit) {
      if (this.subtract(1, 'd').isBusinessDay()) {
        break;
      }

      loop += 1;
    }

    return this;
  };

  dayjsClass.prototype.monthBusinessDays = function(partialEndDate: Dayjs) {
    if (!this.isValid()) {
      return [];
    }

    const day = this.clone().startOf('month');
    const end = partialEndDate || this.clone().endOf('month');
    const daysArr = [];
    let done = false;

    while (!done) {
      if (day.isBusinessDay()) {
        daysArr.push(day.clone());
      }

      if (end.diff(day.add(1, 'd')) < 0) {
        done = true;
      }
    }

    return daysArr;
  };

  dayjsClass.prototype.monthNaturalDays = function(fromToday) {
    if (!this.isValid()) {
      return [];
    }

    const day = fromToday ? this.clone() : this.clone().startOf('month');
    const end = this.clone().endOf('month');
    const daysArr = [];
    let done = false;

    while (!done) {
      daysArr.push(day.clone());

      if (end.diff(day.add(1, 'd')) < 0) {
        done = true;
      }
    }

    return daysArr;
  };

  const _getBusinessWeeks = (self, endDate, startDate) => {
    if (!self.isValid()) {
      return [];
    }

    const day = startDate;
    const end = endDate ? dayjs(endDate).clone() : self.clone().endOf('month');
    const weeksArr = [];
    let daysArr = [];
    let done = false;

    while (!done) {
      if (day.isBusinessDay()) {
        daysArr.push(day.clone());
      }

      if (day.day() === 5) {
        weeksArr.push(daysArr);
        daysArr = [];
      }

      if (end.diff(day.add(1, 'd')) < 0) {
        if (daysArr.length < 5) {
          weeksArr.push(daysArr);
        }

        done = true;
      }
    }

    return weeksArr;
  };

  dayjsClass.prototype.monthBusinessWeeks = function(fromToday) {
    fromToday = fromToday || false;
    const startDate = fromToday ? this.clone() : this.clone().startOf('month');
    return _getBusinessWeeks(this, null, startDate);
  };

  dayjsClass.prototype.businessWeeksBetween = function(endDate) {
    const startDate = this.clone();
    return _getBusinessWeeks(this, endDate, startDate);
  };

  dayjsClass.prototype.monthNaturalWeeks = function(fromToday) {
    if (!this.isValid()) {
      return [];
    }

    const day = fromToday ? this.clone() : this.clone().startOf('month');
    const end = this.clone().endOf('month');
    const weeksArr = [];
    let daysArr = [];
    let done = false;

    while (!done) {
      daysArr.push(day.clone());

      if (day.day() === 6) {
        weeksArr.push(daysArr);
        daysArr = [];
      }

      if (end.diff(day.add(1, 'd')) < 0) {
        if (daysArr.length < 7) {
          weeksArr.push(daysArr);
        }
        done = true;
      }
    }

    return weeksArr;
  };
};
