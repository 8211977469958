import {
  __,
  all,
  allPass,
  anyPass,
  append,
  compose,
  concat,
  converge,
  curry,
  defaultTo,
  difference,
  either,
  equals,
  fromPairs,
  has,
  head,
  identity,
  init,
  keys,
  last,
  length,
  map,
  nAry,
  nth,
  path,
  pathOr,
  pick,
  replace,
  split,
  startsWith,
  tail,
  take,
  values,
  when
} from 'ramda';

import {
  isArray,
  isFunction,
  isNotPlainObj,
  isNotUndefined,
  isPlainObj,
  isString
} from 'ramda-adjunct';

import { arrayify, error, mapIndex, prettyPrint } from './utils';
import { stdlib } from './stdlib';
// import { generateId } from '../core/generateId';

const functionPrefix = ':';
const variableKey = '@';
const invokeKey = `${functionPrefix}$`;
const quoteKey = `${functionPrefix}quote`;
const evalKey = `${functionPrefix}eval`;
const lambdaKey = `${functionPrefix}lambda`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const isResolver = (x) => allPass([ isArray, compose(anyPass(map(equals, arrayify(x))), head, defaultTo([])) ]);

const isFunctionReference = allPass([
  isString,
  startsWith(functionPrefix),
  compose(
    has(__, stdlib),
    replace(functionPrefix, '')
  )
]);

const isFunctionInvocation = allPass([
  isArray,
  compose(
    isFunctionReference,
    head
  )
]);

export const isLambda = allPass([
  isArray,
  compose(
    equals(3),
    length
  ),
  compose(
    equals(lambdaKey),
    head
  ),
  compose(
    isArray,
    nth(1)
  ),
  compose(
    all(equals(true)),
    map(allPass([
      startsWith(variableKey),
      isString
    ])),
    nth(1)
  )
]);

const isCompiledFunctionInvocation = allPass([
  isArray,
  compose(isFunction, head)
]);

const isQuote = isResolver(quoteKey);

const isEval = isResolver(evalKey);

// TypeScript doesn't like the second allPass call
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const isFunctionParameter = either(allPass([ isString, startsWith(variableKey) ]), allPass([ isArray, compose(startsWith(variableKey), head) ]));

const isInvoke = isResolver(invokeKey);

// TODO: Replace with `js-utils` version
const isRecursable = either(isArray, isPlainObj);

// TODO: Replace with `js-utils` version
const getPath = when(
  isString,
  compose(
    map(converge(defaultTo, [ identity, (x) => Number.parseInt(x, 10) ])),
    split('.')
  )
);

// TypeScript doesn't like the type of the __ placeholder
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getParametersByPath = (ps) => compose(path(__, ps), getPath);

const resolveFunctionParameters = (lookup) => (value) => {
  if (isLambda(value)) {
    const params = value[1];
    const arity = length(params);
    const unshadowedKeys = difference(keys(lookup), params);
    // Someone added a bad type definition to the first parameter of pick, so
    // we have to ignore this error (for now).
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const targetedParameters = pick(unshadowedKeys, lookup);
    const targetedResolver = resolveFunctionParameters(targetedParameters);

    return converge(append, [ compose(targetedResolver, last), take(arity) ])(value);
  }
  else if (isFunctionParameter(value)) {
    let result;

    if (isArray(value)) {
      const defaultValue = compose(resolveFunctionParameters(lookup), nth(2))(value);
      const contextPath: any = converge(concat, [ compose(arrayify, head), compose(getPath, nth(1)) ])(value);

      result = pathOr(defaultValue, contextPath, lookup);

      if (result === undefined) {
        throw new Error(error([
          'context'
        ])([
          'Unable to resolve context path. You must provide a valid default value for path-based context access:',
          '\t["@<KEY_1>", "<KEY_2>.<KEY_3>... <KEY_N>" | [<KEY_2>, <KEY_3>,... <KEY_N>], <DEFAULT>]',
          '\nReceived:',
          prettyPrint(value),
          '\nContext:',
          prettyPrint(lookup)
        ], false));
      }
    }
    else {
      result = getParametersByPath(lookup)(value);
    }

    if (isNotUndefined(result)) {
      return result;
    }

    return value;
  }
  else if (isRecursable(value)) {
    return map(resolveFunctionParameters(lookup), value);
  }

  return value;
};

const executeFunctions = (value: any[]) => {
  if (isCompiledFunctionInvocation(value)) {
    const args = map(executeFunctions, tail(value));
    const fn: Function = head(value);

    if (isInvoke(last(args))) {
      return executeFunctions(fn(...init(args))(...tail(last(args))));
    }
    else if (length(args)) {
      return executeFunctions(fn(...args));
    }

    return fn;
  }
  else if (isRecursable(value)) {
    return map(executeFunctions, value);
  }

  return value;
};

const rowan = (value) => {
  if (isQuote(value)) {
    if (length(value) === 1) {
      throw new Error(error([
        'Quotation'
      ])([
        'Quotation requires 1 or more arguments.',
        '\t[":quote", "<@ARG_1>", ("<@ARG_2>", ..."<@ARG_N>")]',
        '\nReceived:',
        prettyPrint(value)
      ], false));
    }

    return value;
  }
  else if (isEval(value)) {
    const evalFn = compose(rowan, when(isQuote, tail));

    if (length(value) >= 2) {
      return evalFn(tail(value));
    }

    return evalFn;
  }
  else if (isLambda(value)) {
    const params = value[1];
    const arity = length(params);
    const body = last(value);

    if (length(value) > 3) {
      throw new Error(error([
        'lambda'
      ])([
        'Incorrect syntax for lambdas:',
        '\t[":lambda", [<@ARG_1>", ("<@ARG_2>", ..."<@ARG_N>",)], <EXPRESSION>]',
        '\nReceived:',
        prettyPrint(value)
      ], false));
    }

    const lambda = (...args) => {
      // TypeScript doesn't like the type of the parameter to mapIndex
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const keyedArgs = compose(fromPairs, mapIndex((name, index) => [name, args[index]]))(params);

      return compose(
        executeFunctions,
        rowan,
        resolveFunctionParameters(keyedArgs)
      )(body);
    };

    // Return the curried lambda
    // TypeScript doesn't like the return type of nAry
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return compose(curry, nAry(arity))(lambda);
  }
  else if (isFunctionInvocation(value)) {
    const functionName = compose(replace(functionPrefix, ''), head)(value);
    const operation = stdlib[functionName];
    const args: any[] = map(rowan, tail(value)) as any[];

    if (isInvoke(last(args))) {
      return operation(...init(args))(...tail(last(args)));
    }
    else if (length(args)) {
      return operation(...args);
    }

    return operation;
  }
  else if (isRecursable(value)) {
    return map(rowan, value);
  }

  return value;
};

const fixCycles = (object) => {
  // const seen = new WeakMap();

  const quickFixCycles = (obj) => {
    // Quickly remove top-level items from the context that contain cycles. These items will not be referenceable from Rowan scripts.
    // This should be much faster than traversing the entire object to try and remove cycles, but preserve the rest of the object.

    // typeof null === 'object', so we have to test for null.
    if ((typeof obj === 'object') && (obj !== null)) {
      const newObject = {};
      // Keys with these names are always removed from the Rowan context as they are known to be
      // added by the ag-grid and popper and very likely contain cycles.
      const keysAlwaysSkipped = [
        'agGridReact',
        'api',
        'columnApi',
        'defaultGridApi',
        'frameworkComponentWrapper',
        'gridApi',
        'gridOptionsWrapper',
        'popper'
      ];

      Object.keys(obj).forEach((key) => {
        if (keysAlwaysSkipped.includes(key)) {
          // Do nothing - these are known ag-grid keys that should not be included in the context.
        }
        else if (obj[key] instanceof Element) {
          // Do nothing - DOM nodes should not be included in the context.
        }
        else if ((key === 'column') || (key === 'node')) {
          if (obj[key].gridApi) {
            // Do nothing - this is an ag-grid column key instead of an application column key.
          }
          else {
            // Copy the value, trusting that it doesn't contain a cycle.
            newObject[key] = obj[key];
          }
        }
        else {
          // Copy over any values that don't meet the above criteria. Due to performance issues, we can't test
          // the rest for cycles, but if they are present in the context they WILL cause Rowan to go into an
          // infinite loop.
          newObject[key] = obj[key];
        }
      });

      return newObject;
    }
    else {
      return obj;
    }
  };

  if (object?.model) {
    return Object.assign({}, object, { model: quickFixCycles(object.model) });
  }
  else {
    return object;
  }
};

export const createProgram = (input, context) => {
  // Clone the context and disconnect any circular references.
  const localContext = fixCycles(context);
  let expression = input;

  if (isNotUndefined(localContext) && isPlainObj(localContext)) {
    expression = [
      [':lambda', map((k) => `@${k}`, keys(localContext)), expression],
      ...values(localContext)
    ];
  }
  else if (isNotUndefined(localContext) && isNotPlainObj(localContext)) {
    throw new Error(error([
      'interpreter',
      'context'
    ])([
      'Context must be an object.',
      '\tinterpreter :: (expression: *, context: { [string]: * }) => *',
      '\nReceived:',
      prettyPrint(localContext)
    ], false));
  }

  return compose(executeFunctions, rowan)(expression);
};

function resolvePath(modelPath: string, originalModel: any, newModel: any): any {
  // Model references start with '::' and then use normal JavaScript object notation.
  // Remove the '::' and then split the rest on '.' to get the references at each level.
  const pathParts = modelPath.substring(1).split('.');
  const value = path(pathParts, originalModel);

  pathParts.reduce((model, part, index, originalArray) => {
    if (index === originalArray.length - 1) {
      // If we're at the end of the object path, set value
      model[part] = value;
    }
    else if (!model[part]) {
      // If this part of the path doesn't exist, we need to create it for the next step
      model[part] = {};
    }

    // Return the current location in the object so we can continue to the next level down in the path
    return model[part];
  }, newModel);
}

function extractModelValues(input, context, newContext = { model: {} }) {
  if (Array.isArray(input)) {
    input.forEach((item) => {
      if (Array.isArray(item)) {
        extractModelValues(item, context, newContext);
      }
      else {
        if (typeof item === 'string' && item.startsWith('@model')) {
          resolvePath(item, context, newContext);
        }
      }
    });
  }
}

export const runScript = (input, context) => {
  let script = input;
  let results;

  if (typeof input === 'string') {
    try {
      script = JSON.parse(script);
    }
    catch (e) {
      console.error(`rowan.runScript(): input ${input} is not valid JSON.`, e);
      return undefined;
    }
  }

  if (script !== undefined) {
    try {
      let newContext = context;

      if (Object.keys(context).length === 1 && context.hasOwnProperty('model')) {
        // This is a model from an MDF application and we need to build a context object that contains only
        // the values referenced from this model so that large models don't cause performance problems.
        const mdfContext = { model: {} };
        // Extract model values actually used by the script and insert them into mdfContext.
        extractModelValues(script, context, mdfContext);
        // Use a context that contains only the referenced values from the application data.
        newContext = mdfContext;
      }

      results = createProgram(script, newContext);
    }
    catch (e) {
      console.error('rowan.runScript(): Error executing script.', e);
      return undefined;
    }
  }

  return typeof results === 'function' ? results() : results;
};
