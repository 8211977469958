import React from 'react';
import DOM from 'react-dom-factories';

import { ModelHelper } from '../core/ModelHelper';
import { RenderHelper } from '../core/RenderHelper';
import { ResponsiveHelper } from '../core/ResponsiveHelper';

export default function createReactComponentRenderer(componentType) {
  return function reactComponentRenderer({ viewName, view, model }) {
    let viewProperties = ModelHelper.resolve(view.properties, model, viewName);

    // In order to get a reference to a view, we need to grab the ref
    // property from the model and add it to the viewProperties. Then,
    // we need to delete the ref property from the model, otherwise it
    // will be reused by the children when they are rendered.
    if (model.ref) {
      viewProperties.ref = model.ref;
      delete model.ref;
    }

    if (viewProperties.responsive) {
      console.error(`reactComponentRenderer(): The responsive property is no longer supported for view ${viewName}. Please remove it from your views.`);
    }

    // injecting responsive class
    viewProperties = ResponsiveHelper.registerResponsive(viewProperties, view.content);

    const viewContent = RenderHelper.renderContent(view.content, model, viewName);
    const viewChildren = Array.isArray(view.content) ? viewContent : [viewContent];

    if (view.events) {
      let viewEvents = ModelHelper.resolve(view.events, model, viewName);
      viewEvents = Array.isArray(viewEvents) ? viewEvents : [viewEvents];

      // Adding events to the properties
      RenderHelper.addEventsToProperties(viewProperties, model, viewEvents);
    }

    // do not pass analyticsCategory, analyticsAction, analyticsLabel properties down (clone before modifications)
    const properties = { ...(viewProperties || {}) };

    delete properties.analyticsCategory;
    delete properties.analyticsAction;
    delete properties.analyticsLabel;

    // filter out boolean HTML attributes that are not `truthy` when rendering OneUX web components and HTML tags.
    // when dealing with Stencil-based web components, their React wrapper does not handle them correctly,
    // so any value that is not 'undefined' will become 'true' inside the Stencil component.
    // in an odd turn, the displayName property is attached to the render function in React-wrapped Stencil components.
    if ((typeof componentType === 'object' && componentType?.render?.displayName) || DOM[view.type]) {
      Object.keys(properties).forEach((prop) => {
        if (properties[prop] === false || properties[prop] === undefined) {
          delete properties[prop];
        }
      });
    }

    // Get rid of empty className properties so that we don't end up with DOM nodes like this: <div class>
    if (DOM[view.type] && !properties.className) {
      delete properties.className;
    }

    return React.createElement(componentType, properties, ...viewChildren);
  };
}
