import { cloneDeep } from 'lodash';
import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import DOM from 'react-dom-factories';
import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

// MDF Framework Dependencies
import { ComponentManager } from './ComponentManager';
import { DeviceHelper } from './DeviceHelper';
import { RendererManager } from './RendererManager';

// MDF Core Components
import Application, { IApplicationProps } from '../components/Application';

// MDF custom renderers
import renderContentSelector from '../renderers/contentSelectorRenderer';
import renderGlobalContentSelector from '../renderers/globalContentSelectorRenderer';
import renderIterator from '../renderers/iteratorRenderer';

declare const window: any;

export class MDFCore {
  private static initialized = false;
  static isProduction = false;

  // Allow the use of localStorage to debug everything
  static shouldLog = () => !!(window?.localStorage?.getItem('mdfDebugAll'));

  static getGoogleMapsKey() {
    const currentPage = document.location.hostname;

    const isDIT = [
      'localhost',
      'localhost.adp.com',
      'localhostcloud.adp.com',
      'localdit.adp.com',
      'localcloud.adp.com',
      'localditcloud.adp.com',
      'wfncloud-predit.nj.adp.com',
      'wfn-dit.nj.adp.com'
    ].includes(currentPage);

    const isFIT = [
      'localhost-fit.adp.com',
      'localhost-fitcloud.adp.com',
      'localfitcloud.adp.com',
      'wfncloud-fit.nj.adp.com',
      'wfncloud-fit-n.nj.adp.com',
      'localfit.adp.com',
      'localfit2.adp.com',
      'wfn-fit.nj.adp.com',
      'wfn-fit-n.nj.adp.com'
    ].includes(currentPage);

    const isProduction = [
      'workforcenow.adp.com',
      'workforcenow.dc1.adp.com',
      'workforcenow.dc2.adp.com',
      'wfn-prod-test.adp.com',
      'workforcenow.cloud.adp.com',
      'workforcenow.poc.adp.com',
      'static.adp.com',
      'static.workforcenow.adp.com'
    ].includes(currentPage);

    if (isProduction) {
      // When the url is production return the production key
      return 'AIzaSyC09OtrjXojxT_6OGAAP0tq8Q38Q2KsSOI';
    }
    else if (currentPage?.includes('-iat.')) {
      // When the url is iAT return the iAT key
      return 'AIzaSyDghoPOC2zA7FKLwhBLakYFvfVldtyflxY';
    }
    else if (currentPage?.includes('-ipe.')) {
      // When the url is the performance environment return the performance key
      return 'AIzaSyDTfoPgsg-MoPWUB61kFw6sYhz36lTR0IM';
    }
    else if (isFIT) {
      // When the url is FIT return the FIT key
      return 'AIzaSyDe9wQ4wyTxlA2_HkDTrk7Z578rpxXGodE';
    }
    else if (isDIT) {
      // When the url is DIT return the DIT key
      return 'AIzaSyA5jmXJMEIehVRoL2SpM8SDbOdsY9aCFrU';
    }
    else {
      // Default to the production API key
      return 'AIzaSyC09OtrjXojxT_6OGAAP0tq8Q38Q2KsSOI';
    }
  }

  static clone(obj: any) {
    // Using cloneDeep since JSON.parse(JSON.stringify(obj)) will eliminate any functions attached to the object being cloned.
    // Should views be immutable?
    return cloneDeep(obj);
  }

  static log(...logArgs) {
    if (!MDFCore.isProduction) {
      console.log(...logArgs);
    }
  }

  static create(ViewComponent: any, props: any, node?: HTMLElement, callback?: any) {
    // The parameter View is upper-case so that JSX treats it as a component and not a html tag
    if (!node) {
      node = document.createElement('div');
      document.body.appendChild(node);
    }

    const root = createRoot(node, callback);
    root.render(<ViewComponent {...props} />);

    return {
      ViewComponent: ViewComponent,
      viewProperties: props,
      node: node,
      update() {
        root.render(<ViewComponent {...props} />);
      }
    };
  }

  // This will be the main API that bootstraps a Redux connected MDF application.
  static createApplication(rootViewName: string, mountDOMNode: HTMLElement, reducer?: (state: any, action: any) => any, forceProduction?: boolean): Promise<any> {
    console.warn(`MDFCore.createApplication(): Creating application ${rootViewName}.`);

    MDFCore.isProduction = !!forceProduction;
    mountDOMNode.setAttribute('data-mdf-root-id', 'application_root');

    return new Promise((resolve) => {
      // Initialize MDF component library
      this.initialize();

      const store = this.createStore(reducer);
      const root: Root = createRoot(mountDOMNode);

      const onMount = (observer: MutationObserver) => {
        if (observer) {
          observer.observe(mountDOMNode, { childList: true });
        }

        // Registering callback that listens to mega menu navigation change
        // and makes sure that the React application is unmounted.
        window['WFNShell']?.setOnNavigate(rootViewName, () => {
          // Remove mounted React component from the DOM and clean up its event handlers and state.
          // But only do this if the mountDOMNode is still connected, otherwise it was likely loaded into
          // some other container that got destroyed.
          if (mountDOMNode.isConnected) {
            // Also wrap it in a try/catch to eat errors if they occur.
            try {
              root.unmount();
            }
            catch (e) {
              // Do nothing.
            }
          }
        });

        resolve(store);
      };

      const applicationProps: IApplicationProps = {
        onMount,
        rootViewName,
        store
      };

      root.render(<Application {...applicationProps} />);
    });
  }

  // Create the Redux store and expose this function for use by application unit tests.
  static createStore(reducer?: (state: any, action: any) => any) {
    const middleware = [];
    const noopReducer = (state: any) => state;

    if (localStorage.getItem('adpWFNReduxLogger')) {
      middleware.push(createLogger());
      MDFCore.isProduction = false;
    }
    else {
      MDFCore.isProduction = true;
    }

    return configureStore({
      reducer: reducer || noopReducer,
      middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middleware)
    });
  }

  // Allow mobile applications to register for the back button event on Android devices. This will
  // do nothing on desktop or iOS based devices.
  static androidBackButtonListener(callback: any) {
    if (DeviceHelper.isADPMobileApp()) {
      DeviceHelper.getADPMobileContainer().Event.addEventListener('BACKBUTTONACTION', (/* _event: any */) => {
        console.log('MDF Android back button event');
        callback && callback();
      }, false);
    }
  }

  static createView(ViewComponent: any, props?: any, children?: any[]) {
    if (typeof ViewComponent === 'string') {
      ViewComponent = ComponentManager.getComponent(ViewComponent);
    }

    // Make sure that we don't try and use 'null' or 'undefined' as a component type.
    if (ViewComponent) {
      return <ViewComponent {...props}>{children}</ViewComponent>;
    }
    else {
      return null;
    }
  }

  static moveFocusTo(id: string) {
    if (!id) {
      // If there's no id, there's nothing to do.
      return;
    }

    const node = document.getElementById(id);

    if (node) {
      if ((node as any).setFocus) {
        // Waypoint components that can receive focus have a setFocus() method.
        (node as any).setFocus();
      }
      else {
        // Call the DOM node's focus function.
        node.focus();
      }
    }
  }

  static initialize() {
    if (!MDFCore.initialized) {
      // Register React HTML elements
      Object.keys(DOM).forEach((element) => {
        ComponentManager.registerComponent(element, element);
      });

      // Register the React.Fragment component
      ComponentManager.registerComponent('Fragment', React.Fragment);

      // MDF custom renderers
      RendererManager.registerRenderer('ContentSelector', renderContentSelector);
      RendererManager.registerRenderer('GlobalContentSelector', renderGlobalContentSelector);
      RendererManager.registerRenderer('Iterator', renderIterator);

      // Initializing Device helper
      DeviceHelper.initialize();

      MDFCore.initialized = true;
    }
    else {
      console.log('The MDFCore is already initialized.');
    }
  }
}
