/* eslint-disable no-duplicate-imports */
/* eslint-disable @typescript-eslint/unbound-method */
// Logic functions
import {
  all,
  allPass,
  and,
  any,
  anyPass,
  both,
  clamp,
  complement,
  cond,
  countBy,
  defaultTo,
  either,
  eqBy,
  equals,
  F,
  gt,
  gte,
  ifElse,
  innerJoin,
  isEmpty,
  isNil,
  lt,
  lte,
  min,
  minBy,
  none,
  not,
  or,
  pathEq,
  pathSatisfies,
  propEq,
  propSatisfies,
  T,
  unless,
  when
} from 'ramda';

import { defaultWhen, isNotEmpty, neither, notAllPass, notBoth } from 'ramda-adjunct';

// Iteration functions
import { until } from 'ramda';

// Math functions
import { add, dec, divide, inc, mathMod, max, maxBy, mean, median, multiply, negate, product, subtract, sum } from 'ramda';

// Arrays functions
import {
  adjust,
  aperture,
  append,
  chain,
  concat,
  difference,
  differenceWith,
  drop,
  dropLast,
  dropLastWhile,
  dropRepeats,
  dropRepeatsWith,
  dropWhile,
  endsWith,
  filter,
  find,
  findIndex,
  findLast,
  findLastIndex,
  flatten,
  fromPairs,
  groupBy,
  groupWith,
  head,
  includes,
  indexBy,
  indexOf,
  init,
  insert,
  insertAll,
  intersection,
  intersperse,
  into,
  last,
  lastIndexOf,
  length,
  map,
  mapAccum,
  mapAccumRight,
  nth,
  pair,
  partition,
  pluck,
  prepend,
  range,
  reduce,
  reduceBy,
  reduceRight,
  reduceWhile,
  reject,
  remove,
  repeat,
  reverse,
  scan,
  slice,
  sort,
  sortBy,
  sortWith,
  startsWith,
  symmetricDifference,
  symmetricDifferenceWith,
  tail,
  take,
  takeLast,
  takeLastWhile,
  takeWhile,
  times,
  transduce,
  transpose,
  traverse,
  unfold,
  union,
  unionWith,
  uniq,
  uniqBy,
  uniqWith,
  unnest,
  update,
  without,
  xprod,
  zip,
  zipObj,
  zipWith
} from 'ramda';

import { concatRight, isArray, isNotArray, omitIndexes, pickIndexes, sliceFrom, sliceTo, stubArray } from 'ramda-adjunct';

// Object functions
import {
  assoc,
  assocPath,
  dissoc,
  dissocPath,
  eqProps,
  evolve,
  has,
  hasIn,
  hasPath,
  invert,
  invertObj,
  keys,
  lens,
  lensIndex,
  lensPath,
  lensProp,
  mapObjIndexed,
  mergeAll,
  mergeDeepLeft,
  mergeDeepRight,
  mergeDeepWith,
  mergeDeepWithKey,
  mergeLeft,
  mergeRight,
  mergeWith,
  mergeWithKey,
  objOf,
  omit,
  over,
  path,
  pathOr,
  pick,
  pickBy,
  project,
  prop,
  propOr,
  props,
  set,
  toPairs,
  values,
  view,
  where,
  whereEq
} from 'ramda';

import {
  flattenPath,
  flattenProp,
  isNotPlainObj,
  isPlainObj,
  lensEq,
  lensIso,
  lensNotEq,
  lensNotSatisfy,
  lensSatisfies,
  mergePath,
  mergePaths,
  mergeProp,
  mergeProps,
  pathNotEq,
  paths,
  propNotEq,
  renameKeys,
  renameKeysWith,
  spreadPath,
  spreadProp,
  stubObj,
  viewOr
} from 'ramda-adjunct';

// Functions
import {
  always,
  ap,
  apply,
  applySpec,
  applyTo,
  ascend,
  binary,
  call,
  comparator,
  compose,
  converge,
  curry,
  descend,
  empty,
  flip,
  identity,
  invoker,
  juxt,
  lift,
  liftN,
  nAry,
  nthArg,
  o,
  once,
  pipe,
  type,
  unapply,
  unary,
  useWith
} from 'ramda';

import { isFunction, isNotFunction, noop, seq, Y } from 'ramda-adjunct';

// String functions
import {
  join,
  match,
  replace,
  split,
  splitAt,
  splitEvery,
  splitWhen,
  test,
  toLower,
  toString,
  toUpper,
  trim
} from 'ramda';

import { isNotString, isString, stubString } from 'ramda-adjunct';

// Boolean functions
import { isBoolean, isNotBoolean } from 'ramda-adjunct';

// Number functions
import {
  isEven,
  isFinite,
  isFloat,
  isInteger,
  isNaN,
  isNegative,
  isNotFinite,
  isNotFloat,
  isNotInteger,
  isNotNaN,
  isNotNumber,
  isNumber,
  isOdd,
  isPositive
} from 'ramda-adjunct';

// Null functions
import { isNotNull, isNull } from 'ramda-adjunct';

// Miscellaneous functions
import { cata, liftF, liftFN } from 'ramda-adjunct';

import { arrayify, error, mapIndex, prettyPrint, trace } from './utils';

const wrapLit = unless(isFunction, always);

// TODO: More of these
// Logic
const notEquals = complement(equals);
const ifRowan = curry((c, t, f) => ifElse(c, wrapLit(t), wrapLit(f)));
const whenRowan = curry((c, t) => when(c, wrapLit(t)));
const unlessRowan = curry((c, t) => unless(c, wrapLit(t)));

// Arrays
const foldl1 = curry((f, c) => reduce(f, head(c), c));
const foldr1 = curry((f, c) => reduceRight(f, head(c), c));
const isLength = curry((x, value) => compose(equals(x), length)(value));

// Combinators
const recursion = curry((conditionsSrc, defaultValueSrc) => (value) => {
  const conditions = defaultTo([], conditionsSrc);
  const defaultValue = defaultTo(identity, defaultValueSrc);

  const prepareConditions = map((c) => {
    if (isArray(c) && isLength(2, c)) {
      // Not sure what this line is doing, but wrapLit may not return an array.
      // It might need to be:
      // return wrapLit(c[1]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return adjust(wrapLit, 1, c);
    }
    else if (isArray(c) && isLength(3, c)) {
      const onCondition = (v) => {
        const r1 = wrapLit(c[1]);
        const r2 = wrapLit(c[2])(v);
        const recurse = recursion(conditions, defaultValue);

        return compose(r2, recurse, r1)(v);
      };

      return [head(c), onCondition];
    }

    throw new Error(error([
      'Standard Library',
      'Combinators',
      '#recursion'
    ])([
      'Each condition must be an array of length 2 (base case) or 3 (recursive step).',
      'Syntax:',
      '\t[',
      '\t\t":recursion",',
      '\t\t[[<CONDITION_1>, <T> | <R1>, <R2>], ([<CONDITION_2>, <T> | <R1>, <R2>], [<CONDITION_3>, <T> | <R1>, <R2>], ...[<CONDITION_N>, <T> | <R1>, <R2>])],',
      '\t\t[<DEFAULT_T> | <DEFAULT_R1>, <DEFAULT_R2>]',
      '\t]',
      '\nReceived:',
      prettyPrint(c),
      '\nIn:',
      prettyPrint([conditions, defaultValue])
    ], false));
  });

  // TypeScript doesn't like the first spread of prepareConditions.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return cond([ ...prepareConditions(conditions), ...prepareConditions([[T, defaultValue]])])(value);
});

export const stdlib = {
  // Logic
  equals,
  '==': equals,
  lt,
  '<': lt,
  lte,
  '<=': lte,
  gt,
  '>': gt,
  gte,
  '>=': gte,
  notEquals,
  '/=': notEquals,
  'if': ifRowan,
  'when': whenRowan,
  'unless': unlessRowan,
  T,
  F,
  'N': () => null,
  allPass,
  any,
  anyPass,
  and,
  '&&': and,
  or,
  '||': or,
  both,
  either,
  complement,
  isEmpty,
  // The value resolver returns the value if the resolution returns
  // undefined. Those strings should always start with @model, so we'll
  // assume (for now) that the resolved value was undefined and return true.
  'isNil': (value: any) => typeof value === 'string' && value.startsWith('@model') ? true : isNil(value),
  defaultTo,
  not,
  '!': not,
  propSatisfies,
  pathSatisfies,
  // TypeScript doesn't like passing the results of map to cond.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  'cond': (cs) => cond(map((c) => [c[0], wrapLit(c[1])], cs)),
  clamp,
  countBy,
  eqBy,
  innerJoin,
  min,
  minBy,
  none,
  pathEq,
  propEq,
  notBoth,
  neither,
  notAllPass,
  isNotEmpty,
  defaultWhen,

  // Iteration
  until,

  // Math
  add,
  '+': add,
  subtract,
  '-': subtract,
  multiply,
  '*': multiply,
  divide,
  '/': divide,
  dec,
  inc,
  max,
  maxBy,
  mean,
  median,
  mathMod,
  negate,
  product,
  sum,
  'abs': Math.abs,
  'cos': Math.cos,
  'cosh': Math.cosh,
  'acos': Math.acos,
  'acosh': Math.acosh,
  'sin': Math.sin,
  'sinh': Math.sinh,
  'asin': Math.asin,
  'asinh': Math.asinh,
  'tan': Math.tan,
  'tanh': Math.tanh,
  'atan': Math.atan,
  'atanh': Math.atanh,
  'cbrt': Math.cbrt,
  'clz32': Math.clz32,
  'ceil': Math.ceil,
  'floor': Math.floor,
  'fround': Math.fround,
  'round': Math.round,
  'exp': Math.exp,
  'expm1': Math.expm1,
  'hypot': Math.hypot,
  'imul': curry(Math.imul),
  'log': Math.log,
  'log10': Math.log10,
  'log1p': Math.log1p,
  'log2': Math.log2,
  'pow': curry((Math.pow)),
  '**': curry((Math.pow)),
  'sqrt': Math.sqrt,
  'trunc': Math.trunc,

  // Arrays
  'Array': Array.of,
  reduce,
  'foldl': reduce,
  reduceRight,
  'foldr': reduceRight,
  'reduce1': foldl1,
  foldl1,
  'reduceRight1': foldr1,
  foldr1,
  map,
  mapIndex,
  all,
  concat,
  adjust,
  zip,
  drop,
  filter,
  head,
  init,
  intersection,
  last,
  length,
  isLength,
  aperture,
  append,
  chain,
  difference,
  differenceWith,
  dropLast,
  dropLastWhile,
  dropRepeats,
  dropRepeatsWith,
  dropWhile,
  endsWith,
  find,
  findIndex,
  findLast,
  findLastIndex,
  flatten,
  fromPairs,
  groupBy,
  groupWith,
  reduceBy,
  reduceWhile,
  reject,
  includes,
  indexBy,
  indexOf,
  insert,
  insertAll,
  intersperse,
  into,
  lastIndexOf,
  mapAccum,
  mapAccumRight,
  nth,
  pair,
  partition,
  pluck,
  prepend,
  range,
  remove,
  repeat,
  reverse,
  scan,
  slice,
  sort,
  sortBy,
  sortWith,
  startsWith,
  symmetricDifference,
  symmetricDifferenceWith,
  tail,
  take,
  takeLast,
  takeLastWhile,
  takeWhile,
  times,
  transduce,
  transpose,
  traverse,
  unfold,
  union,
  unionWith,
  uniq,
  uniqBy,
  uniqWith,
  unnest,
  update,
  without,
  xprod,
  zipObj,
  zipWith,
  isArray,
  isNotArray,
  stubArray,
  pickIndexes,
  concatRight,
  sliceFrom,
  sliceTo,
  omitIndexes,
  arrayify,

  // Objects
  'Object': Object.create,
  'isObject': isPlainObj,
  assoc,
  assocPath,
  dissoc,
  dissocPath,
  eqProps,
  evolve,
  has,
  hasIn,
  mergeAll,
  mergeDeepLeft,
  mergeDeepRight,
  mergeDeepWith,
  mergeDeepWithKey,
  mergeLeft,
  mergeRight,
  mergeWith,
  mergeWithKey,
  invert,
  invertObj,
  keys,
  lens,
  lensIndex,
  lensPath,
  lensProp,
  mapObjIndexed,
  objOf,
  omit,
  over,
  path,
  pathOr,
  pick,
  pickBy,
  project,
  prop,
  propOr,
  props,
  set,
  toPairs,
  values,
  view,
  where,
  whereEq,
  isNotPlainObj,
  stubObj,
  paths,
  renameKeys,
  renameKeysWith,
  mergeProps,
  mergePaths,
  mergeProp,
  mergePath,
  viewOr,
  hasPath,
  spreadProp,
  spreadPath,
  flattenProp,
  flattenPath,
  lensEq,
  lensNotEq,
  lensSatisfies,
  lensNotSatisfy,
  lensIso,
  propNotEq,
  pathNotEq,

  // Null
  isNull,
  isNotNull,

  // Logging
  trace,
  'stdout': console.log,

  // Combinators
  applyTo,
  'thrush': applyTo,
  Y,
  'S': ap,
  recursion,

  // Functions
  compose,
  '<|': compose,
  pipe,
  '|>': pipe,
  call,
  ap,
  apply,
  applySpec,
  ascend,
  binary,
  comparator,
  converge,
  descend,
  empty,
  flip,
  identity,
  invoker,
  juxt,
  lift,
  liftN,
  nAry,
  nthArg,
  o,
  once,
  unapply,
  unary,
  useWith,
  isFunction,
  isNotFunction,
  noop,
  seq,
  liftFN,
  liftF,
  cata,

  // Strings
  join,
  match,
  replace,
  split,
  splitAt,
  splitEvery,
  splitWhen,
  test,
  toLower,
  toUpper,
  toString,
  trim,
  isString,
  isNotString,
  stubString,

  // Numbers
  isNumber,
  isPositive,
  isNegative,
  isFinite,
  isNotFinite,
  isInteger,
  isNotInteger,
  isFloat,
  isNotFloat,
  isNotNumber,
  isNaN,
  isNotNaN,
  isOdd,
  isEven,

  // Other
  type,
  'dup': (x) => [x, x],
  'dupAt': (index, arr) => insert(index, arr[index], arr),

  // Boolean
  isBoolean,
  isNotBoolean,

  // RegEx
  'RegEx': RegExp
};
